import axios from '@/plugins/axiosV2'

const http = axios(process.env.VUE_APP_API_CONDUCEF + '/quejas');

export default {

    listar_quejas() {
        return http.get('/');
    },

    crear_queja(payload) {
        return http.post('/', payload);
    },

    actualizar_queja(folio, payload) {
        return http.put(`/${folio}`, payload);
    },

    actualizar_queja_evento(queja_id, payload) {
        return http.post(`/${queja_id}/eventos`, payload);
    }
}